import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Icon,
  Grid,
  Container,
  IconButton,
  Typography,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import {Skeleton} from '@mui/lab';
import {NIL as NIL_UUID} from 'uuid';
import {Progress} from './components/Progress';
import {QuestionWrapper} from './components/QuestionWrapper';
import {NotFoundText, ReadMore} from 'shared/components';
import {useStyles} from './styles';
import {useStudentQuestions} from './hooks';
import {
  IStudentMultiplier,
  IStudentQuestion,
} from 'shared/services/api/student-quiz/QuestionList';

export const StudentQuestionList: React.FC = () => {
  const navigate = useNavigate();
  const styles = useStyles();

  const [isChange, setIsChange] = useState(false);

  const {
    isLoading,
    multiplierPosition,
    idPessoaConvite,
    nameSection,
    multiplierList,
    numberOfQuestions,
    numberOfAnsweredQuestions,
    quizColor,
  } = useStudentQuestions();

  const [currentMultiplier, setCurrentMultiplier] =
    useState<IStudentMultiplier>({} as IStudentMultiplier);
  const [currentQuestions, setCurrentQuestions] = useState<IStudentQuestion[]>(
    [],
  );

  useEffect(() => {
    setCurrentMultiplier(multiplierList[multiplierPosition]);

    if (multiplierList[multiplierPosition]) {
      setCurrentQuestions(multiplierList[multiplierPosition].questoes);
    }
  }, [multiplierList, multiplierPosition]);

  useEffect(() => {
    setIsChange(true);
    setTimeout(() => {
      setIsChange(false);
    }, 2000);
  }, [currentMultiplier?.nome]);

  return (
    <Box className={styles.root}>
      <Container className={styles.container}>
        <Box
          display="flex"
          className={styles.card}
          style={{borderColor: `#${quizColor}`}}
          justifyContent={'space-between'}>
          {isLoading ? (
            <Box width="100%">
              <Skeleton width="40%" height={35} />
              <Box marginBottom={1} />
              <Skeleton width="40%" height={21} />
              <Box marginBottom={1} />
              <Skeleton width="40%" height={21} />
            </Box>
          ) : (
            <Box>
              <ReadMore
                cutAt={80}
                text={nameSection}
                color="primary"
                variant="h5"
                gutterBottom
                style={{fontWeight: 700}}
              />
              {currentMultiplier ? (
                <>
                  <Typography color="primary" variant="body1" gutterBottom>
                    {currentMultiplier.nomeTurma}
                  </Typography>

                  <Typography color="primary" variant="body1" gutterBottom>
                    {currentMultiplier.nome}
                  </Typography>

                  <Typography color="primary" variant="body1" gutterBottom>
                    {currentMultiplier.nomeDisciplina}
                    {isChange ? <LinearProgress /> : <></>}
                  </Typography>

                  <Typography color="textSecondary" variant="body1">
                    {currentMultiplier.professor}
                  </Typography>
                </>
              ) : (
                <NotFoundText />
              )}
            </Box>
          )}
          <Box>
            <IconButton
              size="medium"
              onClick={() =>
                idPessoaConvite === NIL_UUID
                  ? navigate(`/pagina-inicial`)
                  : navigate(`/convite/${idPessoaConvite}`)
              }>
              <Icon>close</Icon>
            </IconButton>
          </Box>
        </Box>
        <Box marginY={5} />
        <Box width={'100%'}>
          <Grid container>
            <Grid item xs={12}>
              {isLoading ? (
                <CircularProgress color={'primary'} size={30} />
              ) : (
                currentMultiplier && (
                  <QuestionWrapper questions={currentQuestions} />
                )
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box className={styles.footer} style={{backgroundColor: `#${quizColor}`}}>
        <Container>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item xs={6} sm={5} md={3}>
              <div>
                {currentMultiplier && (
                  <Typography className={styles.colorWhite}>
                    {currentMultiplier.nome}
                  </Typography>
                )}
                <Typography
                  className={styles.colorWhite}
                  style={{
                    width: 'calc(100% - 10px)',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}>
                  <strong>{nameSection}</strong>
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} md={6} className={styles.gridProgress}>
              <Box margin={2} className={styles.colorWhite}>
                <Progress
                  count={numberOfQuestions}
                  currentPosition={numberOfAnsweredQuestions}
                  text="respondidas"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              sm={7}
              md={7}
              className={styles.gridAnsweredQuestions}>
              <Typography align="right" className={styles.colorWhite}>
                {`${numberOfAnsweredQuestions} de ${numberOfQuestions} respondidas`}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
