import { useCallback, useMemo } from "react";
import { IOption, IQuestion } from "shared/services/api/quiz/Quiz";
import {v4 as uuid} from 'uuid';
import { sectionsEmitChange } from "../observables/Observable";
import { useQuiz } from "./useQuiz";

interface ITipoIntencaoAvaliacao {
  professorResponsavel: boolean;
  professorAtivo: boolean;
}

export const useSection = (sectionOrder: number) => {
  const {getQuiz} = useQuiz();

  const sectionPosition = useMemo(() => {
    return sectionOrder-1
  },[sectionOrder])

  const getSession = useCallback(() => getQuiz().sections[sectionPosition],[getQuiz, sectionPosition]);

  const getSessionName = useCallback(() => getQuiz().sections[sectionPosition].name,[getQuiz, sectionPosition]);

  const setSectionName = useCallback((name: string) => {
    getQuiz().sections[sectionPosition].name = name;
  },[getQuiz, sectionPosition]);

  const getSessionDescription = useCallback(() =>
    getQuiz().sections[sectionPosition].description,[getQuiz, sectionPosition]);

  const setSectionDescription = useCallback((description: string) => {
    getQuiz().sections[sectionPosition].description = description;
  },[getQuiz, sectionPosition]);

  const getSessionMultiplyDiscipline = useCallback(() =>
    getQuiz().sections[sectionPosition].multiplyDiscipline,[getQuiz, sectionPosition]);

  const setSectionMultiplyDiscipline = useCallback((value: boolean) => {
    getQuiz().sections[sectionPosition].multiplyDiscipline = value;
  },[getQuiz, sectionPosition]);

  const getSessionMultiplyCourse = useCallback(() => getQuiz().sections[sectionPosition].multiplyCourse,[getQuiz, sectionPosition]);

  const setSectionMultiplyCourse = useCallback((value: boolean) => {
    getQuiz().sections[sectionPosition].multiplyCourse = value;
  },[getQuiz, sectionPosition]);

  const getQuestionsOrders = useCallback(() =>
    getQuiz().sections[sectionPosition].questions.map(
      (question) => ({id: question.id, order:question.order}),
    ),[getQuiz, sectionPosition]);

  const getSectionPapers = useCallback(() => {
    return getQuiz().sections[sectionPosition].paperGroups},[getQuiz, sectionPosition]);


  const setSectionPapersToggle = useCallback((paperId: string) => {
    const index =
      getQuiz().sections[sectionPosition].paperGroups.indexOf(paperId);
    if (index !== -1) {
      getQuiz().sections[sectionPosition].paperGroups.splice(index, 1);
    } else {
      getQuiz().sections[sectionPosition].paperGroups.push(paperId);
    }
    sectionsEmitChange();
  },[getQuiz, sectionPosition]);

  const getEvaluationIntentType = useCallback(() => {
    return {
        professorResponsavel: getQuiz().sections[sectionPosition].responsibleTeacher,
        professorAtivo: getQuiz().sections[sectionPosition].activeTeacher,
      }
  }, [getQuiz, sectionPosition]);

  const setEvaluationIntentType = useCallback((tipoIntencaoAvaliacao: ITipoIntencaoAvaliacao ) => {
    getQuiz().sections[sectionPosition].responsibleTeacher = tipoIntencaoAvaliacao.professorResponsavel;
    getQuiz().sections[sectionPosition].activeTeacher = tipoIntencaoAvaliacao.professorAtivo;
  }, [getQuiz, sectionPosition]);

  const duplicateSection = useCallback(() => {
    const newSection = JSON.parse(JSON.stringify(getQuiz().sections[sectionPosition]))
    newSection.id = `newSection__${uuid()}`;
    newSection.order = getQuiz().sections[sectionPosition].order+1;

    newSection.questions.forEach((question: IQuestion) => {
      question.id = `newQuestion__${uuid()}`;
      question.columnOptions.forEach((option: IOption) => {
        option.id = `newOption__${uuid()}`;
      })
      question.rowOptions?.forEach((option: IOption) => {
        option.id = `newOption__${uuid()}`;
      })
    })

    getQuiz().sections.splice(sectionPosition+1, 0, newSection);
    getQuiz().sections.forEach((section, index) => {
      section.order = index+1;
    })

    sectionsEmitChange();
  },[getQuiz, sectionPosition]);

  const deleteSection = useCallback(() => {
    getQuiz().sections.splice(sectionPosition, 1);
    getQuiz().sections.forEach((section, index) => {
      section.order = index + 1;
    });
    sectionsEmitChange();
  },[getQuiz, sectionPosition]);

  const getSectionIsSelected = useCallback(() => {
    return getQuiz().sections[sectionPosition].isSelected;
  },[getQuiz, sectionPosition]);

  const setSectionIsSelected = useCallback((value: boolean) => {
    getQuiz().sections[sectionPosition].isSelected = value;
  },[getQuiz, sectionPosition]);

  const setSectionEvaluationIntent = useCallback((value: string) => {
    getQuiz().sections[sectionPosition].evaluationIntent = value;
  }, [getQuiz, sectionPosition]);

  const getSectionEvaluationIntent = useCallback(() => {
    return getQuiz().sections[sectionPosition].evaluationIntent;
  }, [getQuiz, sectionPosition]);

  return {
    getSession,
    getSessionName,
    setSectionName,
    getSessionDescription,
    setSectionDescription,
    duplicateSection,
    deleteSection,
    getQuestionsOrders,
    getSessionMultiplyDiscipline,
    setSectionMultiplyDiscipline,
    getSessionMultiplyCourse,
    setSectionMultiplyCourse,
    getSectionPapers,
    setSectionPapersToggle,
    getSectionIsSelected,
    setSectionIsSelected,
    setSectionEvaluationIntent,
    getSectionEvaluationIntent,
    setEvaluationIntentType,
    getEvaluationIntentType
  };
}

