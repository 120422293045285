import {Api} from 'shared/services';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';
import {TSatisfaction} from '../quiz/Quiz';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';

export interface IStudentQuestion {
  idQuestao: string;
  idResposta: string;
  enunciado: string;
  descricao: string;
  permiteComentario: boolean;
  respondido: boolean;
  tipoQuestao: TTypeOfQuestion;
  validacao: string;
  operador: string;
  ordem: number;
  obrigatoria: boolean;
  campoValidacao: string;
  campoAuxValidacao: string;
  mensagemErro: string;
  embaralhaRespostas: boolean;
  iniciaNoZero: boolean;
  validarRespostas: string;
  usarPeso: boolean;
  respostaTexto: string;
  comentario: string;
  opcoesRespostaLinha: IOptions[];
  idDisciplina: string;
}

export interface IOptions {
  id: string;
  descricao: string;
  tipoIcone: string;
  iconeCor: string;
  selecionado: boolean;
  opcoesRespostaColuna: IOptions[];
  satisfacao: TSatisfaction;
}

export interface IStudentMultiplier {
  codigo: string;
  nome: string;
  professor: string;
  todasRespondidas: boolean;
  questoes: IStudentQuestion[];
  codigoTurma: number;
  nomeTurma: string;
  nomeDisciplina?: string;
}

export interface ISectionQuestions {
  idSecao: string;
  nomeSecao: string;
  mensagemFinalQuestionario?: string;
  idProximaSecao: string;
  idPessoaConvite: string;
  ordem: number;
  statusPessoaConvite: 'ATIVO' | 'RESPONDIDO' | 'EXPIRADO';
  multiplicadores: IStudentMultiplier[];
  corQuestionario?: string;
}

const getById = async (
  idPessoaConvite: string,
  idSecao: string,
): Promise<IResultOfAction<ISectionQuestions>> => {
  try {
    const {data} = await Api.get(
      `/ConvitesRecebidos/${idPessoaConvite}/secao/${idSecao}/questoes`,
    );
    const sectionQuestions: ISectionQuestions = {
      idSecao: data.IdSecao,
      idProximaSecao: data.IdProximaSecao,
      nomeSecao: data.NomeSecao,
      mensagemFinalQuestionario: data.MensagemFinalQuestionario,
      idPessoaConvite: data.IdPessoaConvite,
      ordem: data.Ordem,
      statusPessoaConvite: data.StatusPessoaConvite,
      corQuestionario: data.CorQuestionario,
      multiplicadores: data.Multiplicadores.map((multiplicador: any) => ({
        codigo: multiplicador.Codigo,
        nome: multiplicador.Nome,
        professor: multiplicador.Professor,
        todasRespondidas: multiplicador.TodasRespondidas,
        nomeTurma: multiplicador.NomeTurma,
        nomeDisciplina: multiplicador.NomeDisciplina,
        codigoTurma: multiplicador.CodigoTurma,
        questoes: multiplicador.Questoes.map((questao: any) => ({
          idQuestao: questao.IdQuestao,
          idResposta: questao.IdResposta,
          enunciado: `${questao.Obrigatoria ? '*' : ''}${questao.Ordem}. ${
            questao.Enunciado
          }`,
          descricao: questao.Descricao,
          idDisciplina: questao.IdDisciplina,
          permiteComentario: questao.PermiteComentario,
          respondido: questao.Respondido,
          tipoQuestao: questao.TipoQuestao,
          validacao: questao.Validacao,
          operador: questao.Operador,
          ordem: questao.Ordem,
          obrigatoria: questao.Obrigatoria,
          campoValidacao: questao.CampoValidacao,
          campoAuxValidacao: questao.CampoAuxValidacao,
          mensagemErro: questao.MensagemErro,
          embaralhaRespostas: questao.EmbaralharRespostas,
          iniciaNoZero: questao.IniciaNoZero,
          validarRespostas: questao.ValidarRespostas,
          usarPeso: questao.UsarPeso,
          respostaTexto: questao.RespostaTexto || '',
          comentario: questao.Comentario || '',
          opcoesRespostaLinha: questao.OpcoesRespostaLinha.map(
            (opcao: any) => ({
              id: opcao.Id,
              descricao: opcao.Descricao,
              tipoIcone: opcao.TipoIcone,
              iconeCor: opcao.IconeCor,
              selecionado: opcao.Selecionado,
              opcoesRespostaColuna: opcao.OpcoesRespostaColuna.map(
                (opcao: any) => ({
                  id: opcao.Id,
                  descricao: opcao.Descricao,
                  tipoIcone: opcao.TipoIcone,
                  iconeCor: opcao.IconeCor,
                  selecionado: opcao.Selecionado,
                }),
              ),
            }),
          ),
        })),
      })),
    };

    const response: IResultOfAction<ISectionQuestions> = {
      success: true,
      message: '',
      data: sectionQuestions,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const putById = async (
  idResposta: string,
  question: IStudentQuestion,
): Promise<IResultOfAction<any>> => {
  try {
    const gridAnswers: any[] = question.opcoesRespostaLinha
      .filter((optionRow) => optionRow.selecionado)
      .map((optionRow) => ({
        Linha: optionRow.id,
        Colunas: optionRow.opcoesRespostaColuna
          .filter((optionColumn) => optionColumn.selecionado)
          .map((optionColumn) => optionColumn.id),
      }));

    const putObject: any = {
      IdResposta: idResposta,
      RespostaTexto: question.respostaTexto,
      Comentario: question.comentario,
      Respostas: gridAnswers,
    };

    const {data} = await Api.put(
      `/ConvitesRecebidos/Resposta/${idResposta}`,
      putObject,
    );

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getComprovante = async (
  IdPessoaConvite: string,
): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.get(
      `/Relatorios/Impressao/ComprovanteParticipacao/${IdPessoaConvite}`,
    );
    const response: IResultOfAction<any> = {
      success: true,
      message: '',
      data: data,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getErp = async (): Promise<IResultOfAction<any>> => {
  try {
    const {data, headers} = await Api.get(`/ConvitesRecebidos`);
    const response: IResultOfAction<any> = {
      success: true,
      message: '',
      data: data,
      headers: headers,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const StudentQuestionListService = {
  getById,
  getErp,
  putById,
  getComprovante,
};
