import {Api} from 'shared/services/axios-config';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';
import {IValidation} from 'pages/quiz/components/validation/Validation';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

export interface IQuizLogo {
  logoBase64: string;
  logoExtension: string;
}

export interface IQuizBanner {
  bannerBase64: string;
  bannerExtension: string;
}

export interface IQuizDetail {
  id: string;
  idQuestionaryType: string;
  nameQuestionaryType: string;
  name: string;
  title: string;
  showProgressBar?: boolean;
  description: string;
  finalMessage: string;
  bannerBase64?: string;
  bannerExtension?: string;
  logoBase64?: string;
  logoExtension?: string;
  hasBanner?: boolean;
  hasLogo?: boolean;
  sections: ISection[];
  establishment: number[];
  hasInviteSent: boolean;
  quizColor: string;
  active: boolean;
}

export interface IQuestion {
  id: string;
  isRequired: boolean;
  order: number;
  name: string;
  description: string;
  type: TTypeOfQuestion;
  hasValidation: boolean;
  hasComment: boolean;
  hasShuffleAnswer: boolean;
  hasWeight: boolean;
  isSelected: boolean;
  validation: IValidation;
  columnOptions: IOption[];
  rowOptions?: IOption[];
}

export interface IOption {
  id: string;
  description: string;
  order: number;
  iconOrColor?: string;
  weight?: number;
  satisfaction?: TSatisfaction;
}

export interface ISection {
  id: string;
  idQuiz: string;
  name: string;
  description: string;
  order: number;
  isSelected: boolean;
  multiplyDiscipline: boolean;
  multiplyCourse: boolean;
  evaluationIntent: string;
  responsibleTeacher: boolean;
  activeTeacher: boolean;
  questions: IQuestion[];
  paperGroups: string[];
}

export type TSatisfaction = 'satisfeito' | 'insatisfeito';

const getById = async (id: string): Promise<IResultOfAction<IQuizDetail>> => {
  try {
    const {data} = await Api.get(`/questionarios/${id}`);

    const quizDetail = {
      id: data.Id,
      hasInviteSent: data.PossuiConviteEnviado,
      idQuestionaryType: data.IdTipoQuestionario,
      nameQuestionaryType: data.NomeTipoQuestionario,
      name: data.NomeQuestionario,
      title: data.TituloQuestionario,
      description: data.Descricao,
      finalMessage: data.MensagemFinal,
      hasBanner: data.PossuiBanner,
      quizColor: data.CorQuestionario,
      active: data.Ativo,
      establishment: data.Estabelecimentos.map((estab: any) => {
        return estab.IdEstabelecimento as number;
      }),
      sections: data.Secoes.map((secao: any) => ({
        id: secao.Id,
        idQuiz: secao.IdQuestionario,
        name: secao.NomeSecao,
        description: secao.Descricao,
        order: secao.Ordem,
        multiplyDiscipline: secao.MultiplicarDisciplina,
        multiplyCourse: secao.MultiplicarCurso,
        evaluationIntent: secao.IntencaoAvaliacao,
        responsibleTeacher: secao.ProfessorResponsavel,
        activeTeacher: secao.ProfessorAtivo,
        isSelected: false,
        questions: secao.Questoes.map((questao: any) => ({
          id: questao.Id,
          isRequired: questao.Obrigatoria,
          order: questao.Ordem,
          name: questao.Enunciado,
          description: questao.Descricao,
          type: questao.TipoQuestao,
          hasValidation: questao.ValidarRespostas,
          hasComment: questao.PermiteComentario,
          hasShuffleAnswer: questao.EmbaralhaRespostas,
          hasWeight: questao.UsarPeso,
          validation: {
            typeOfValidation: questao.Validacao,
            value: questao.CampoValidacao,
            valueEnd: questao.CampoAuxValidacao,
            operator: questao.Operador,
            customErrorMessage: questao.MensagemErro,
          },
          isSelected: false,
          columnOptions: questao.OpcoesRespostaColuna.map((col: any) => ({
            id: col.Id,
            description: col.Descricao,
            order: col.Ordem,
            weigh: col.Peso,
            iconOrColor: col.IconeCor || '',
            iconType: col.TipoIcone,
          })),
          rowOptions: questao.OpcoesRespostaLinha.map((row: any) => ({
            id: row.Id,
            description: row.Descricao,
            order: row.Ordem,
            weigh: row.Peso,
            iconOrColor: row.IconeCor || '',
            iconType: row.TipoIcone,
            satisfaction: row.Satisfacao,
          })),
        })),
        paperGroups: secao.GrupoPapeis.map((paper: any) => {
          return paper.IdGrupoPapel;
        }),
      })),
    };

    const response: IResultOfAction<IQuizDetail> = {
      success: true,
      message: '',
      data: quizDetail,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getBannerById = async (
  quizId: string,
): Promise<IResultOfAction<IQuizBanner>> => {
  try {
    const {data} = await Api.get(`/questionarios/${quizId}/banner`);

    const quizBanner: IQuizBanner = {
      bannerBase64: data.BannerBase64,
      bannerExtension: data.ExtensaoBanner,
    };

    const response: IResultOfAction<IQuizBanner> = {
      data: quizBanner,
      message: '',
      success: true,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getLogoById = async (
  quizId: string,
): Promise<IResultOfAction<IQuizLogo>> => {
  try {
    const {data} = await Api.get(`/questionarios/${quizId}/logo`);

    const quizLogo: IQuizLogo = {
      logoBase64: data.LogoBase64,
      logoExtension: data.ExtensaoLogo,
    };

    const response: IResultOfAction<IQuizLogo> = {
      data: quizLogo,
      message: '',
      success: true,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const create = async (quiz: IQuizDetail): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.post('/questionarios', {
      Id: quiz.id,
      IdTipoQuestionario: quiz.idQuestionaryType,
      NomeQuestionario: quiz.name,
      TituloQuestionario: quiz.title,
      MostraBarraProgresso: false,
      Descricao: quiz.description,
      MensagemFinal: quiz.finalMessage,
      ExtensaoBanner: quiz.bannerExtension,
      BannerBase64: quiz.bannerBase64,
      ExtensaoLogo: quiz.logoExtension,
      LogoBase64: quiz.logoBase64,
      CorQuestionario: quiz.quizColor,
      Estabelecimentos: quiz.establishment,
      Ativo: quiz.active,
      Secoes: quiz.sections.map((section) => ({
        NomeSecao: section.name,
        Descricao: section.description,
        Ordem: section.order,
        MultiplicarDisciplina: section.multiplyDiscipline,
        MultiplicarCurso: section.multiplyCourse,
        IntencaoAvaliacao: section.evaluationIntent,
        ProfessorResponsavel: section.responsibleTeacher,
        ProfessorAtivo: section.activeTeacher,
        GruposPapeis: section.paperGroups,
        Questoes: section.questions.map((question) => ({
          TipoQuestao: question.type,
          Validacao: question.validation.typeOfValidation,
          Operador: question.validation.operator,
          Enunciado: question.name,
          Descricao: question.description,
          Ordem: question.order,
          Obrigatoria: question.isRequired,
          CampoValidacao: question.validation.value,
          CampoAuxValidacao: question.validation.valueEnd,
          EmbaralhaRespostas: question.hasShuffleAnswer,
          MensagemErro: question.validation.customErrorMessage,
          PermiteComentario: question.hasComment,
          UsarPeso: question.hasWeight,
          validarRespostas: question.hasValidation,
          OpcoesRespostaColuna: question.columnOptions.map((column) => ({
            Descricao: column.description,
            Ordem: column.order,
            IconeCor: column.iconOrColor,
            Peso: column.weight,
          })),
          OpcoesRespostaLinha: question.rowOptions?.map((row) => ({
            Descricao: row.description,
            Ordem: row.order,
            IconeCor: row.iconOrColor,
            Peso: row.weight,
            Satisfacao: row.satisfaction,
          })),
        })),
      })),
    });

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const updateById = async (quiz: IQuizDetail): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.put(`/questionarios/${quiz.id}`, {
      Id: quiz.id,
      IdTipoQuestionario: quiz.idQuestionaryType,
      NomeQuestionario: quiz.name,
      TituloQuestionario: quiz.title,
      MostraBarraProgresso: false,
      Descricao: quiz.description,
      MensagemFinal: quiz.finalMessage,
      ExtensaoBanner: quiz.bannerExtension,
      BannerBase64: quiz.bannerBase64,
      ExtensaoLogo: quiz.logoExtension,
      LogoBase64: quiz.logoBase64,
      CorQuestionario: quiz.quizColor,
      Estabelecimentos: quiz.establishment,
      Secoes: quiz.sections.map((section) => ({
        NomeSecao: section.name,
        Descricao: section.description,
        Ordem: section.order,
        MultiplicarDisciplina: section.multiplyDiscipline,
        MultiplicarCurso: section.multiplyCourse,
        IntencaoAvaliacao: section.evaluationIntent,
        ProfessorResponsavel: section.responsibleTeacher,
        ProfessorAtivo: section.activeTeacher,
        GruposPapeis: section.paperGroups,
        Questoes: section.questions.map((question) => ({
          TipoQuestao: question.type,
          Validacao: question.validation.typeOfValidation,
          Operador: question.validation.operator,
          Enunciado: question.name,
          Descricao: question.description,
          Ordem: question.order,
          Obrigatoria: question.isRequired,
          CampoValidacao: question.validation.value,
          CampoAuxValidacao: question.validation.valueEnd,
          EmbaralhaRespostas: question.hasShuffleAnswer,
          MensagemErro: question.validation.customErrorMessage,
          PermiteComentario: question.hasComment,
          UsarPeso: question.hasWeight,
          validarRespostas: question.hasValidation,
          OpcoesRespostaColuna: question.columnOptions.map((column) => ({
            Descricao: column.description,
            Ordem: column.order,
            IconeCor: column.iconOrColor,
            Peso: column.weight,
          })),
          OpcoesRespostaLinha: question.rowOptions?.map((row) => ({
            Descricao: row.description,
            Ordem: row.order,
            IconeCor: row.iconOrColor,
            Peso: row.weight,
            Satisfacao: row.satisfaction,
          })),
        })),
      })),
    });

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const deletePermanentById = async (
  idQuestionario: string,
  nomeQuestionario: string,
): Promise<IResultOfAction<any>> => {
  try {
    const {data} = await Api.delete(
      `/questionarios/${idQuestionario}/excluirPermanentemente?nomeQuestionario=${nomeQuestionario}`,
    );

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const changeQuizStatus = async (
  quizId: string,
  status: boolean,
): Promise<IResultOfAction<any>> => {
  try {
    const patchBody: any = {
      Id: quizId,
      Ativo: status,
    };

    const {data} = await Api.patch(
      `/questionarios/${quizId}/ativarInativar`,
      patchBody,
    );

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const updateWithIvniteById = async (
  quiz: IQuizDetail,
): Promise<IResultOfAction<any>> => {
  try {
    const patchBody: any = {
      Id: quiz.id,
      NomeQuestionario: quiz.name,
      TituloQuestionario: quiz.title,
      IdTipoQuestionario: quiz.idQuestionaryType,
      MensagemFinal: quiz.finalMessage,
      BannerBase64: quiz.bannerBase64,
      ExtensaoBanner: quiz.bannerExtension,
      LogoBase64: quiz.logoBase64,
      ExtensaoLogo: quiz.logoExtension,
      CorQuestionario: quiz.quizColor,
      Estabelecimentos: quiz.establishment,
      Secoes: quiz.sections.map((sec) => ({
        Id: sec.id,
        NomeSecao: sec.name,
        Descricao: sec.description,
        Questoes: sec.questions.map((quest) => ({
          Id: quest.id,
          Enunciado: quest.name,
          Descricao: quest.description,
          Obrigatoria: quest.isRequired,
          PermiteComentario: quest.hasComment,
          OpcoesRespostaColuna: quest.columnOptions.map((colOpt) => ({
            Id: colOpt.id,
            Descricao: colOpt.description,
            IconeCor: colOpt.iconOrColor,
          })),
          OpcoesRespostaLinha:
            quest.rowOptions?.map((rowOpt) => ({
              Id: rowOpt.id,
              Descricao: rowOpt.description,
              IconeCor: rowOpt.iconOrColor,
              Satisfacao: rowOpt.satisfaction,
            })) || [],
        })),
      })),
    };

    const {data} = await Api.patch(
      `/questionarios/${quiz.id}/editarCampos`,
      patchBody,
    );

    const response: IResultOfAction<any> = {
      data: data.Data,
      success: data.Success,
      message: data.Message,
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const QuizService = {
  create,
  updateById,
  getById,
  getBannerById,
  getLogoById,
  deletePermanentById,
  changeQuizStatus,
  updateWithIvniteById,
};
